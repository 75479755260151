import React from "react";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Button,
  useDisclosure,
  Image,
  SimpleGrid,
  chakra,
    useColorModeValue,
    VisuallyHidden,
    Alert,
    AlertIcon 
} from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { HamburgerIcon } from "@chakra-ui/icons";
import Account from "./Account";
import Address from "./Address";
import Balance from "./Balance";
import Blockies from "react-blockies";

// Note: This code could be better,
// so I'd recommend you to understand how I solved and you could write yours better :)
// Good luck! 🍀

// Update: Check these awesome headers from Choc UI 👇
// https://choc-ui.tech/docs/elements/headers

const SocialButton = ({
  children,
  label,
  href,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.600', 'whiteAlpha.900'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const HeaderAndNav = (
    {address,
    userProvider,
    localProvider,
    mainnetProvider,
    price,
    minimized,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    blockExplorer,
    isSigner,
  networkDisplay}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());


  const modalButtons = [];
  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <Button
          colorScheme="teal"
          key="logoutbutton"
          style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
          onClick={logoutOfWeb3Modal}
          size="sm"
        >
          Logout
        </Button>,
      );
    } else {
      modalButtons.push(
        <Button
          colorScheme="teal"  
          key="loginbutton"
          style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
          type={minimized ? "default" : "primary"}     /* too many people just defaulting to MM and having a bad time */
          onClick={loadWeb3Modal}
          size="sm"
        >
          Connect
        </Button>,
      );
    }
  }


  

  return (
    


    <Flex
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={6}
      bg="#272935"
      color="white"
    >
    <Alert status="info">
      <AlertIcon />
      <Heading as="h5"><Text textColor="black">Minting coming to Rinkeby, soon!</Text></Heading>
    </Alert>
      <Flex align="center" mr={5}>

      {/* <Image width="12" src="logo.svg" /> */}
        {/* <Heading as="h1" size="lg" letterSpacing={"tighter"}>
          Chakra UI
        </Heading> */}
        <Stack ml={{md: '8', lg: '14'}} direction="row" p="10">
            <Heading><Text as="h4" color="white" mr="16"><a href="/" mr="10"><b>Home</b></a></Text></Heading>
            <Heading><Text as="h4" color="white"><a href="/gallery" mr="10"><b>Gallery</b></a></Text></Heading>       
        </Stack>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <HamburgerIcon color="black" w={6} h={6} />
      </Box>




      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
      <Stack
        direction={{ base: "column", md: "row" }}
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
      >
        {/* <Text color="gray.800"><a href="/">Home</a></Text>
        <Text color="gray.800"><a href="/gallery">Gallery</a></Text>
        <Text color="gray.800"><a href="/contract">Contract</a></Text> */}

        
        <Box display={ { base: isOpen ? "none" : "block", md: "block" } }>
            <Blockies seed={address.toLowerCase()} size={6} />
        </Box>
        <Box>
        <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={14}
  /> 
  </Box>
        
        <Box><Text ml="2" mr="2" dropShadow="md" backgroundColor="teal.100" mt="6" fontSize="13" as="a" textColor="white"><Balance address={address} provider={localProvider} price={price} /></Text></Box>
        <Box><Text zIndex="docked">{networkDisplay}</Text></Box>
        <Box>{modalButtons}</Box>
      </Stack>
      </Box>

    </Flex>
  );
};

export default HeaderAndNav;
