import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import baseTheme from "./themes/baseTheme";


const subgraphUri = "http://localhost:8000/subgraphs/name/scaffold-eth/your-contract";

const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ChakraProvider theme={baseTheme}>
  <ApolloProvider client={client}>
        <App subgraphUri={subgraphUri} />
  </ApolloProvider>
  </ChakraProvider>
,
  document.getElementById("root"), 
);
