module.exports = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "plebID",
        "type": "uint256"
      },
      {
        "internalType": "contract IPlebness",
        "name": "attributes",
        "type": "address"
      }
    ],
    "name": "birthPleb",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct IPlebMaker.Pleb",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];