import React from 'react';
import { Accordion, AccordionPanel, AccordionIcon, AccordionButton, AccordionItem, Box, Stack, Flex, Heading, Text, useBreakpointValue, Link, UnorderedList, ListItem, Center } from '@chakra-ui/react';


export default function Faq(){
    return (
      <Center backgroundColor="#272935">
<Stack>
        <Flex  width={{ base: 'lg', md: 'xl', lg: '9xl' }} mt="10" mb="10" position="relative" fontSize={{base: 'xl', md: 'xl', lg: '2xl'}}> 
          <Stack spacing={6} w={'full'}>
            <Heading textAlign="center"
textColor="white" as="h1" size="4xl">
      
                FAQ
              <br />{' '}
            </Heading>
<Accordion alignSelf="center" allowToggle width={{ base: 'lg', md: 'md', lg: 'xl', xl:'8xl' }} backgroundColor="gray.400">




  {/* ITEM - 1  */}
  <AccordionItem>
    <h2>
      <AccordionButton _expanded={{ bg: "#ff1010", color: "white" }} _focus={{ outline: 0}}>
        <Box flex="1" textAlign="left">
          <Heading as="h3">Overview</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <UnorderedList>
      <ListItem>Fixed supply, 8910 Plebs to be ever minted</ListItem>
      <ListItem>No pre-mint, anyone will be able to mint a Pleb when the contract is live</ListItem>
      <ListItem>100% on-chain, no offchain dependecies like IPFS</ListItem>
      <ListItem>Artwork will be in <Link color="#ff1010" href="https://creativecommons.org/publicdomain/zero/1.0/" isExternal>public domain</Link></ListItem>
      <ListItem>No explicit rules for scaricity, all Plebs are equal!</ListItem>
      <ListItem>0 rolyaties on secondary market sales</ListItem>
      <ListItem>Community focused and driven</ListItem>
    </UnorderedList>
      
    </AccordionPanel>
  </AccordionItem>
{/* ITEM - 2  */}

<AccordionItem>
    <h2>
      <AccordionButton _expanded={{ bg: "#ff1010", color: "white" }} _focus={{ outline: 0}}>
        <Box flex="1" textAlign="left">
          <Heading as="h3">Who are The Plebs?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        The Plebs are interspecies, gender fluid creatures. Their saritorial/fashion choices are mostly dubious - with pretensions of belonging to high society, a bit whimsical, but always interesting. 
        They are sceptical about crypto and do not have great things to say about it. They have been groomed to have a firm belief in authority and banks. 
        They tend to think Block Chain, Bit Coin and Etherium <i>(sic)</i> are designed to take their fiat away. It's not the malice in their heart but pure ignorance, upbringing and a little pettiness 
        that makes them think these things. <br /><br />
        As crypto missionaries and followers of our lord Vitalik, it's our responsibility to cure The Plebs of their ignorance and pettiness, hold them by hand and lead them to the forever land of Ethereum. 
        Each pleb brought to crypto fold is a soul saved and fulfils our divine purpose of being.
    </AccordionPanel>
  </AccordionItem>

  {/* ITEM - 3  */}

<AccordionItem>
    <h2>
      <AccordionButton _expanded={{ bg: "#ff1010", color: "white" }} _focus={{ outline: 0}}>
        <Box flex="1" textAlign="left">
          <Heading as="h3">Smart Contracts</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        The Plebs smart contracts are responsible for bringing the avatars into being. The contracts make sure that there are on no off-chain dependencies to generate and access the characters. The characters depend
        only on Ethereum for permanence. <br />

        Once on mainnet, we will open-source all the smart cotnracts.<br />
        More details about the contracts will be shared soon.
    </AccordionPanel>
  </AccordionItem>

  {/* ITEM - 4  */}

  <AccordionItem>
    <h2>
      <AccordionButton _expanded={{ bg: "#ff1010", color: "white" }} _focus={{ outline: 0}}>
        <Box flex="1" textAlign="left">
          <Heading as="h3">Artwork</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>

      Plebs are stored on Ethereum and do not utilize any off-chain services like IPFS or external servers. To make this possible we have created highly compressed body parts which are stored on Ethereum.

      The parts are efficiently assembled into a single SVG and encoded into Base64 format. <br /><br />

      For the parts and accessory colors we have decided to trust math and color theory rather than going for a fixed palette. The custom library generates a palette of 5 colors for each avatar based on Complementary Color theory. The colors in 
      the palette are used to color accessories and background. <br /><br />

      Going by Ethereum's open philosophy We will licence all the artwork created as a part of this project under <Link color="#ff1010" href="https://creativecommons.org/publicdomain/zero/1.0/" isExternal><b>Creative Commons: Public Domain</b></Link> License. 
        
    </AccordionPanel>
  </AccordionItem>  
</Accordion>
          </Stack>
        </Flex>

    
</Stack>
</Center>
      );
}



