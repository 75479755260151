import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
    ButtonGroup,
    Heading,
    Wrap,
    WrapItem,
    Avatar,
    Portal,
    Image
  } from '@chakra-ui/react';
  import { FaDiscord, FaTwitter } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import React from 'react';
  import TeamInfo from './TeamInfo';
  
  const SocialButton = ({
    children,
    label,
    href,
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.600', 'whiteAlpha.900'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function Footer() {
    return (
      <>

      <Box
        bg={useColorModeValue('#1DB39C', '#03FFD9')}
        color={useColorModeValue('gray.700', 'gray.200')} py={4} >
        {/* <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}> */}
          <Stack direction={'row'} spacing={6} ml={{base: "16", md: "32"}} size>
            <Heading as="h3" size="md"><a href="https://twitter.com/theplebsNFT" target="_blank">TWITTER</a></Heading>
            <Heading as="h3" size="md"><a href="https://discord.gg/AJmYZSDsY4" target="_blank">DISCORD</a></Heading>
            {/* <SocialButton label={'Twitter'} href={'https://twitter.com/theplebsNFT'} isExternal> 
              <FaTwitter />
            </SocialButton> */}
            {/* <SocialButton label={'Discord'} href={'https://discord.gg/vZJuW4dH'}>
              <FaDiscord />
            </SocialButton> */}
          </Stack>
 
        {/* </Container> */}
      </Box>
      </>
    );
  }