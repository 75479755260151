import { Alert, Button, Card, Col, Input, List, Row, Spin, Space, Skeleton, Empty, Pagination } from "antd";
import {Text, Container, Heading, Stack, VisuallyHiddenInput }from "@chakra-ui/react"
import React from "react"




export default function ImageGallery(props) {



    
    const handleClick = (value) => {
        alert("redeeming: " + value);
        //event.preventDefault();
        props.tx( props.writeContracts.PlebsToken.redeem(value) )
      }
    



return(

<Stack backgroundColor="#272935">
<Container maxW="container.xl" id={'gallery'} py="0" >
    {/* <Heading mb="10" textAlign="start" textColor="white">Pleb Gallery</Heading> */}

    <List
        grid={{
        column: 5,
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 6,
        xxl: 6,
        }}
        pagination={{
            showSizeChanger: true,
            position: "bottom",
            pageSize: 12
          }}    
        dataSource={props.yourCollectibles}
        locale= {props.yourCollectibles === undefined ? {emptyText: "No Plebs Minted"} : {emptyText: <Empty>When Minted, Your Plebs Will Load Here</Empty>} }
        //locale= { {emptyText: "Loading.."} }
        renderItem={item => (
        <>
        <List.Item>
            <Card style={{background: "#A0AEC0"}} size="small" title={"Pleb #" + item.id}>
                <a width="10" href={"https://testnets.opensea.io/assets/"+(props.readContracts && props.readContracts.PlebsToken && props.readContracts.PlebsToken.address)+"/"+item.id} target="_blank">
                <img src={item.image} />
                {/* <div>{item.description}</div> */}
                </a>
            </Card>
            <Stack backgroundColor="#A0AEC0">
                <Button onClick={() => {
                    handleClick(item.id);
                    }}
                boxShadow="sm" _hover={{boxShadow: 'md'}} _active={{boxShadow: 'xl'}}
                backgroundColor="teal.300"
                variant='solid' width="lg">REDEEM</Button>

      </Stack>
        </List.Item>
        </>
        )}
        />
    </Container>
    </Stack>
    );
}


