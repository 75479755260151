module.exports = [
  {
    "anonymous": false,
    "inputs": [],
    "name": "AssetsLocked",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "string",
        "name": "baseURI",
        "type": "string"
      }
    ],
    "name": "BaseURIUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bool",
        "name": "enabled",
        "type": "bool"
      }
    ],
    "name": "DataURIToggled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addEyeAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addEyeGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addFaceAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addFacialHairAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addHeadGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addJacketAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addMakeupAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addNoseAndMouthAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addShirtAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addSmokeAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "assets",
        "type": "bytes[]"
      }
    ],
    "name": "addTieAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "areAssetsLocked",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "baseURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct IPlebMaker.Pleb",
        "name": "pleb",
        "type": "tuple"
      }
    ],
    "name": "dataURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "eyeGearAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eyeGearSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eyeGearTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eyeTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "eyes",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "faceSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "faceTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "faces",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "facialHairAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "facialHairSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "facialHairTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct IPlebMaker.Pleb",
        "name": "pleb",
        "type": "tuple"
      }
    ],
    "name": "generateSVGImage",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct IPlebMaker.Pleb",
        "name": "pleb",
        "type": "tuple"
      }
    ],
    "name": "genericDataURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForEyeGearAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForFaceAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForFacialHairAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForHeadGearAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForJacketAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForMakeupAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForShirts",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForSmokeAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSpreadForTieAssets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "headGearAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headGearSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headGearTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "isDataURIEnabled",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "jacketSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "jacketTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "jackets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "lockAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "makeupAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "makeupSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "makeupTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "noseAndMouthAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "noseAndMouthTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_baseURI",
        "type": "string"
      }
    ],
    "name": "setBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForEyeGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForFaceAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForFacialHairAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForHeadGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForJacketAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForMakeupAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForShirts",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForSmokeAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "spread",
        "type": "uint256"
      }
    ],
    "name": "setSpreadForTieAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "shirtTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "shirts",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "smokeSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "smokeTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "smokingAssets",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tieSpreadFactor",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tieTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "ties",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggleDataURIEnabled",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct IPlebMaker.PlebFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct IPlebMaker.Pleb",
        "name": "pleb",
        "type": "tuple"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];