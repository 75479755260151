import {
    //Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
    Button,
    Box,
    AspectRatioBox,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Slider,
    SliderTrack,
    SliderThumb,
    SliderFilledTrack,
    Alert,
    AlertIcon
  } from '@chakra-ui/react';

  import React from "react";
  import {useState, useEffect} from "react";
  import { Link } from 'react-router-dom';


  
  export default function TextAndImage({tx, isSigner, loadWeb3Modal, writeContracts, mintFee, gasLimit}) {

    //let jpgDataUrlPrefix = 'data:image/png;base64,';

    const [value, setValue] = React.useState(1);
    const handleChange = value => setValue(value);

    const handleSubmit = (event) => {
      //alert("Minting coming to Rinkeby, soon!");
      event.preventDefault();
      tx( writeContracts.PlebsToken.mint(value,{value: mintFee*value, gasLimit: gasLimit*value}) )
    }


    return (
      <>
      <Stack direction={{ base: 'column', md: 'column', lg: 'row' }} backgroundColor="#272935">

        <Flex ml={{ base: '14', md: '20', lg: '32' }} direction={{ base: 'column', md: 'row' }} mr={10}>
        <Stack>
        <Heading as="h1" fontSize={{ base: '8xl', md: '5xl', lg: '6xl', xl: "9xl" }} >
              <Text
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontWeight="extrabold">
                Onchain, <br />
              </Text>
              <Text textColor="#ff1010">Unruggable!</Text>
              <br />
        </Heading>
        <Flex maxWidth="md">
        <Text mt="-30" textColor="white" fontSize="2xl">
        Each Pleb is backed by 80% of the mint price and ongoing secondary sales royalties (80%). The ETH (sales + accumulated royalties) will be stored in the Plebs smart contract. When a Pleb is redeemed it will be burnt and the owner will get their share of eth from the smart contract.
        </Text> 
        </Flex>
        </Stack> 
        <Image ml={{base: '0', md: '20', lg: '32', xl: '64' }} mt={{base: '20', md: '10', lg: '10'}} mb="20" width={{base: 'lg', md: 'sm', lg: 'md', xl: "xl" }} src="images/RECOVER_plebs.gif"></Image>
        </Flex>
      </Stack>
      <Stack backgroundColor="#272935">
        {isSigner?(
              <form onSubmit={handleSubmit}>
              <Stack spacing={8} ml={{ base: '14', md: '20', lg: '32' }} mt="8">
              <Flex width="md">
                    <NumberInput
                      maxW="100px"
                      mr="2rem"
                      value={value}
                      onChange={handleChange}
                      defaultValue={1}
                    />
                    <Slider flex="1" value={value} onChange={handleChange} min={1} max={5} >
                      <SliderTrack />
                      <SliderFilledTrack />
                      <SliderThumb
                        backgroundColor="teal.300"
                        fontSize="sm"
                        width="32px"
                        height="20px"
                        children={value}
                      />
                    </Slider>
              </Flex>     

                <Button type={"submit"} 
                boxShadow="sm" _hover={{boxShadow: 'md'}} _active={{boxShadow: 'xl'}}
                backgroundColor="teal.300"
                variant='solid' width="md">MINT</Button>
              </Stack>

              </form>  
              ):(
                <Button ml={{ base: '14', md: '20', lg: '32' }} mt="8" boxShadow="sm" _hover={{boxShadow: 'md'}} _active={{boxShadow: 'xl'}}
                backgroundColor="teal.300"
                variant='solid' width="md" type={"primary"} onClick={loadWeb3Modal}>CONNECT WALLET</Button>     
                ) }
      </Stack>
      </>
      
    );
  }