module.exports = [
  {
    "anonymous": false,
    "inputs": [],
    "name": "AssetsLocked",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "string",
        "name": "baseURI",
        "type": "string"
      }
    ],
    "name": "BaseURIUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bool",
        "name": "enabled",
        "type": "bool"
      }
    ],
    "name": "DataURIToggled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addEyeAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addEyeGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addFaceAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addFacialHairAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addHeadGearAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addJacketAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addMakeupAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addNoseAndMouthAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addPocketSquareAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addShirtAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addSmokeAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "assets",
        "type": "string[]"
      }
    ],
    "name": "addTieAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "areAssetsLocked",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "baseURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "pocketSquare",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct INormieMaker.Normie",
        "name": "normie",
        "type": "tuple"
      }
    ],
    "name": "dataURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "eyeAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "eyeGearAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "eyeGearAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eyeGearTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eyeTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "eyes",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "faceAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "faceTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "faces",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "facialHairAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "facialHairAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "facialHairTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "pocketSquare",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct INormieMaker.Normie",
        "name": "normie",
        "type": "tuple"
      }
    ],
    "name": "generateSVGImage",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "pocketSquare",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct INormieMaker.Normie",
        "name": "normie",
        "type": "tuple"
      }
    ],
    "name": "genericDataURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "headGearAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "headGearAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headGearTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "isDataURIEnabled",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "jacketAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "jacketTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "jackets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "lockAssets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "makeupAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "makeupAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "makeupTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "noseAndMouthAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "noseAndMouthAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "noseAndMouthTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "pocketSquareAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pocketSquareTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "pocketSquares",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_baseURI",
        "type": "string"
      }
    ],
    "name": "setBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "shirtAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "shirtTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "shirts",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "smokeAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "smokeTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "smokingAssets",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "tieAtIndex",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tieTypesCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "ties",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggleDataURIEnabled",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "jacket",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "pocketSquare",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "shirt",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "tie",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieBody",
            "name": "body",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint24",
                "name": "eyes",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "eyeGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "face",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "facialHair",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "headGear",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "makeup",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "noseAndMouth",
                "type": "uint24"
              },
              {
                "internalType": "uint24",
                "name": "smoke",
                "type": "uint24"
              }
            ],
            "internalType": "struct INormieMaker.NormieFace",
            "name": "face",
            "type": "tuple"
          },
          {
            "internalType": "string[]",
            "name": "colors",
            "type": "string[]"
          }
        ],
        "internalType": "struct INormieMaker.Normie",
        "name": "normie",
        "type": "tuple"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];